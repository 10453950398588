import { CToastType } from '@cscfi/csc-ui';

export default defineNuxtRouteMiddleware(async (to) => {
  const { addNotification } = useNotificationStore();

  const { projects } = useProjectStore();

  const projectExistsInStore = projects.value.some(
    (project) => project.CSCPrjNum === to.params.projectNumber,
  );

  if (!projectExistsInStore) {
    const { getProjects } = useProjectStore();

    await getProjects();
  }

  if (
    !projects.value.some(
      (project) => project.CSCPrjNum === (to.params.projectNumber as string),
    )
  ) {
    addNotification('invalidProjectNumber', {
      type: CToastType.Error,
      title: 'Could not view the project',
      message: 'The project might not exist or you are not a member',
    });

    return navigateTo({ name: 'Projects' });
  }
});
